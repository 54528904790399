<template>
    <default-layout>

        <div class="spainhn-solid-red-background d-flex py-3 blog-main" style="height:auto; min-height:75px; width:100%">
            <p class="my-auto ps-4 spainhn-subtitle-2 text-white">Noticias Destacadas</p>
        </div>

        <div class="container-fluid px-2 px-md-5">
            
            <div class="d-flex mb-4 my-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards v-for="(noticia, index) in noticias" v-bind:key="index" @goToEntrada="goToBlog($event)"
                        :date="new Date(noticia.fechaEntrada).toLocaleDateString()"
                        :descripcion = "noticia.resumen"
                        :entradaId="noticia.blogId"
                        :imageUrl="`${imagenesUrl}/entradas_blog/${noticia.blogId}/${noticia.imagen}`"
                        :title="noticia.titulo" />
                </div>
            </div>

        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import Cards from '../components/Cards5.vue'

export default {
    components:{
        DefaultLayout,
        Cards
    },
    data(){
        return{
            noticias: []
        }
    },
    setup() {
        
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    methods:{
        /**
         * Esta función te lleva a la entrada de blog/noticia seleccionada
         * @param {event} evento - evento que indica el blog al que se quiere ingresar 
         */
        goToBlog(evento){
            // console.log(evento);
            this.$router.push(`/blog/${evento.entradaId}`)
        }
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
    },
    created(){
        let $vm = this;
        ApiService.getBlogs(null)
            .then( result => {
                console.log(result);
                $vm.noticias = result.data;
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>
<style scoped>
   @media (max-width: 1440px){
        .blog-main .spainhn-subtitle-2{
            font-size: 24px;
        }
    }
</style>