<template>
    <div class="card my-auto mx-2 mt-2" v-bind:style="{backgroundImage: image}">
        
        <div class="container">

          <div class="card-body">
            <small style="font-size:10px">{{descripcion}}</small>
            <strong class="d-block spainhn-text">{{title}}</strong>
            <strong class="d-block spainhn-text">{{date}}</strong>
            <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 mx-auto" size="sm" @click="goToEntrada">
              <div class="d-flex justify-content-center align-items-center">
                  <div class="my-auto me-2">
                    <font-awesome-icon class="my-auto" icon="fa-newspaper"></font-awesome-icon>
                  </div>
                  <strong class="spainhn-text">
                      {{ $t('default.NEWS_SEE_ENTRY')}}
                  </strong>
              </div>
            </mdb-btn>  
          </div>
        </div>
    </div>
</template>

<script>
export default {
    components:{

    },
    props:{
      imageUrl: String,
      title: String,
      descripcion: String,
      date: String,
      entradaId: Number,
    },
    data(){
      return{
        // image: `url(${require('@/assets/img_avatar.png')})`
        // image: `url(${this.imageUrl})`,
        // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
      }
    },
    computed:{
      image(){
        let urlEncoded = encodeURI(this.imageUrl);

        return `url(${urlEncoded})`
      }
    },
    setup() {
        
    },
    methods:{
      goToEntrada(){
        this.$emit('goToEntrada', {entradaId:this.entradaId})
      }
    }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  /* height:55vh;  */
  /* width:45%; */
  height:24rem;
  width:20rem;
  min-width: 250px;
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
}

.card-body{
  height: 65%;
  padding: 0% 10%;
  padding-bottom: 2%;
  padding-top: 5%;
  background-color: rgba(0,0,0,0.7);
  color:white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  /* -ms-flex-item-align:end; */
}
</style>