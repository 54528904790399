var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card my-auto mx-2 mt-2",
      style: { backgroundImage: _vm.image },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("small", { staticStyle: { "font-size": "10px" } }, [
              _vm._v(_vm._s(_vm.descripcion)),
            ]),
            _c("strong", { staticClass: "d-block spainhn-text" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("strong", { staticClass: "d-block spainhn-text" }, [
              _vm._v(_vm._s(_vm.date)),
            ]),
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mt-1 mb-2 mx-auto",
                attrs: { color: "indigo", size: "sm" },
                on: { click: _vm.goToEntrada },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-auto me-2" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "my-auto",
                          attrs: { icon: "fa-newspaper" },
                        }),
                      ],
                      1
                    ),
                    _c("strong", { staticClass: "spainhn-text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("default.NEWS_SEE_ENTRY")) + " "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }