var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex py-3 blog-main",
        staticStyle: { height: "auto", "min-height": "75px", width: "100%" },
      },
      [
        _c("p", { staticClass: "my-auto ps-4 spainhn-subtitle-2 text-white" }, [
          _vm._v("Noticias Destacadas"),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-2 px-md-5" }, [
      _c("div", { staticClass: "d-flex mb-4 my-4" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
          },
          _vm._l(_vm.noticias, function (noticia, index) {
            return _c("cards", {
              key: index,
              attrs: {
                date: new Date(noticia.fechaEntrada).toLocaleDateString(),
                descripcion: noticia.resumen,
                entradaId: noticia.blogId,
                imageUrl: `${_vm.imagenesUrl}/entradas_blog/${noticia.blogId}/${noticia.imagen}`,
                title: noticia.titulo,
              },
              on: {
                goToEntrada: function ($event) {
                  return _vm.goToBlog($event)
                },
              },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }